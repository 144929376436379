.operationControl > div > div > span {
  min-width: 6rem;
}
.itemLabel {
  display: inline-block;
  width: 3rem;
}
.publishColor {
  color: #9dcd67;
}
